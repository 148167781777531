* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

:root{
    --color:#008bff;
}


body , #root {
    display: grid;
    place-content: center;
    height: 100vh;
    width: 100vw;
    overflow: auto;
}
.card.dark {
    --color:#22d722;
}

::-webkit-scrollbar {
    width: 2px;
    height: fit-content;
}

::-webkit-scrollbar-thumb {
    background-color: #22d722;
    border-radius: 6px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

.card {
    margin: 32px 0 32px 0;
    background: #ffffff21;
    color: white;
    border-radius: 12px;
    padding: 16px;
    max-width: 400px;
    width: 100%;
    overflow-y: scroll;
    position: relative;

}

.card h1 {
    font-size: 20px;
}

.card p {
    font-size: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.text-center {
    text-align: center;
}
.profile .avatar{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border:4px solid var(--color);
}

.card .btn_action {
    padding: 10px 12px;
    text-decoration: none;
    color: white;
    width: 100%;
    border-radius: 8px;
    border:1px solid var(--color);
    font-size: 16px;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: flex;
    align-items: center;
}
.card .btn_action span{
    margin-left: 8px;
}
.btn_action:hover{
    background: var(--color);
    border:1px solid white
}
.bg_content{
    background: #2927278f;
    text-align: center;
}
.rd_12{
    border-radius: 12px;
}
.p_8{
    padding: 8px;
}
.card_body{
    min-width: 350px;
    z-index: 2;
    position: relative;
}
.higthlight{
    color: var(--color);
    font-weight: 600;
}
.toggle-btn,.dark-mode {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.toggle-btn{
    z-index: 3;
    background-color: rgb(46, 42, 42);
    border:2px solid var(--color);
    box-shadow: 1px 1px 4px 3px #bfb3b3be;
}
.card .dark-mode{
    z-index: 1;
    background-color: #323030;
    transition:1s ease-in;
    transform: scale(0);
    border:1px dashed var(--color);

}
.card.dark .toggle-btn{
    background-color: #eedede;
}
.card.dark .dark-mode{
    transition:1s ease-in;
    transform: scale(50);
}

.text-name{
    font-size: 20px;
    font-weight: bold;
    color: #d7a722;
}

.gambar {
    width: -webkit-fill-available;
    border-radius: 5px;
}

.bg-text {
    place-content: center;
}

.mt-5 {
    margin-top: 5px;
}

.bg-style {
    background-color: cornflowerblue;
    margin-top: 30px;
}

.svg-image {
    margin-top: 15px;
    text-align: center;
    padding: 0 5px 0 5px;
}

.svg-image img {
    padding: 10px;
}

.foother {
    border-top: 2px solid;
    text-align: center;
    color: #d7a722;
}